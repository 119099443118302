"use client"

import MessagePage from "ui/MessagePage";

export default function NotFound() {
  return (
    <>
      <MessagePage
        text={"מצטערים, הגעתם לעמוד ללא מוצא"}
        imageDesktop={"/404desktop.png"}
        imageMobile={"/404mobile.png"}
        imageHeight={460}
        imageWidth={616}
      />
    </>
  )
}
